import { device } from 'src/styles/breakpoints'
import { gray, grayscale, green, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

// Coins
import BackgroundCoinsSimuladorInterLoopLG from './assets/img/background-coins-simulador-inter-loop-lg.png'
import BackgroundCoinsSimuladorInterLoopMD from './assets/img/background-coins-simulador-inter-loop-md.png'
import BackgroundCoinsSimuladorInterLoopMobile from './assets/img/background-coins-simulador-inter-loop-mobile.png'
import BackgroundCoinsSimuladorInterLoopXL from './assets/img/background-coins-simulador-inter-loop-xl.png'
import BackgroundCoinsSimuladorInterLoopXXL from './assets/img/background-coins-simulador-inter-loop-xxl.png'

// Multiple Coins
import BackgroundMultipleCoinsSimuladorInterLoopLG from './assets/img/background-multiple-coins-simulador-inter-loop-lg.png'
import BackgroundMultipleCoinsSimuladorInterLoopMD from './assets/img/background-multiple-coins-simulador-inter-loop-md.png'
import BackgroundMultipleCoinsSimuladorInterLoopMobile from './assets/img/background-multiple-coins-simulador-inter-loop-mobile.png'
import BackgroundMultipleCoinsSimuladorInterLoopXL from './assets/img/background-multiple-coins-simulador-inter-loop-xl.png'
import BackgroundMultipleCoinsSimuladorInterLoopXXL from './assets/img/background-multiple-coins-simulador-inter-loop-xxl.png'

interface IFeedbackMessage {
 textColor: string;
}

interface IOutputValue {
  error: boolean;
 }

export const CardBlock = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  background-color: ${white};

  padding: 24px;
  
  width: 100%;
  height: 242px;

  position: relative;

  &:before {
    content: url(${BackgroundCoinsSimuladorInterLoopMobile});
    position: absolute;
    bottom: -33px;
    left: -22px;
  }

  &::after {
    content: url(${BackgroundMultipleCoinsSimuladorInterLoopMobile});
    position: absolute;
    top: -21px;
    right: -17px;
  }

  @media ${device.tablet} {
    width: 300px;
    height: 238px;
    padding: 20px;

    &:before {
      content: url(${BackgroundCoinsSimuladorInterLoopMD});
    }
    &::after {
      content: url(${BackgroundMultipleCoinsSimuladorInterLoopMD});
    }
  }

  @media ${device.desktopLG} {
    width: 376px;
    height: 244px;

    &:before {
      content: url(${BackgroundCoinsSimuladorInterLoopLG});
    }
    &::after {
      content: url(${BackgroundMultipleCoinsSimuladorInterLoopLG});
      top: -30px;
      right: -29px;
    }
  }

  @media ${device.desktopXL} {
    width: 386px;

    &:before {
      content: url(${BackgroundCoinsSimuladorInterLoopXL});
    }
    &::after {
      content: url(${BackgroundMultipleCoinsSimuladorInterLoopXL});
    }
  }

  @media ${device.desktopXXXL} {
    &:before {
      content: url(${BackgroundCoinsSimuladorInterLoopXXL});
    }
    &::after {
      content: url(${BackgroundMultipleCoinsSimuladorInterLoopXXL});
    }
  }
`

export const OutputBox = styled.div`
  width: 100%;
  height: 74px;
  border-radius: 8px;
  background-color: ${gray[400]};
  padding: 12px;
`

export const InputContainer = styled.div`
  color: ${green[400]};
`

export const LoopInput = styled.input`
  color: ${orange.extra};
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  font-family: 'Sora', Helvetica, sans-serif;

  text-align: center;

  border: none;

  width: 80%;

  border-bottom: 1px solid ${grayscale[300]};
  padding-bottom: 8px;
  margin-bottom: 4px;
  &:focus-visible {
    outline: none;
  }
`

export const OutputValue = styled.span<IOutputValue>`
  color: ${green[400]};
  filter: ${(props: IOutputValue) => props.error && 'blur(4px)'};
  transition: 300ms ease-in-out;
`

export const FeedbackMessage = styled.span<IFeedbackMessage>`
  color: ${(props: IFeedbackMessage) => props.textColor};
`
