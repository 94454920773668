import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const ContainerReinvista = styled.div`

  .card-carousel {
    font-family: "Sora", Helvetica, sans-serif;
    background-color: ${white};
    height: 147px;
    padding: 16px;

    @media ${device.desktopLG} {
      height: 168px;
    }
  }

  .react-multi-carousel-track {
    padding-top: 40px;
  }

  .react-multi-carousel-dot--active {
    button {
      background: ${orange.extra};
    }
  }

  .react-multi-carousel-dot-list {
    @media ${device.desktopLG} {
      width: 70%;
    }

    .react-multi-carousel-dot {
      button {
        background: ${orange.extra};
      }
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 0;
  }
  .react-multiple-carousel__arrow--right {
    @media ${device.desktopLG} {
      right: calc(30% + 1px);
    }
  }
`
